














import { Vue,Component } from "vue-property-decorator"

interface ToAllListType<T=string>{
    ListIcon :T;
    handleToPath():void;
}

@Component({ name:"ToAllList" })
export default class ToAllList extends Vue implements ToAllListType{
   ListIcon = require("$icon/Express/ToList.png");
   handleToPath(){
       this.$router.push({
           path:"/allExpress"
       })
   }
}
