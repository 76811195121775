




































import { Dialog,Notify } from "vant"
import { GetDefaultAddress } from "@/Api/Basics/index"
import { UserStore } from "@/store/modules/User"
import { AddressType } from "@/Type/index"
import Stroage from "@/util/Storage"
import { Vue,Component,Prop,Emit, Watch } from "vue-property-decorator"

interface AddressTypeT<T=string>{
    loadingShow:boolean
    rightIcon:T;
    AddressIcon:T;
    putIcon:T;
    AddressData:AddressType
    userId:number|T;
    token:T;

    handleToPath():void;
    handleGetAddress(): void
    handleError(data:string):void;
}

@Component({ name:"Address" })
export default class Address extends Vue implements AddressTypeT{
    loadingShow = false
    rightIcon = require("$icon/Basics/Right.png");
    AddressIcon = require("$icon/Basics/location.png");
    putIcon = require("$icon/Express/Put.png")
    AddressShow = false; // 是否显示地址 
    AddressData:AddressType = {}
    userId:number|string = "";
    token = ""

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")   

        if ( JSON.stringify( UserStore.GetSelAddress ) !== "{}" ) {
            this.AddressShow = true
            this.AddressData = UserStore.GetSelAddress
            this.PullAddress( this.AddressData )
        }else{
            this.handleGetAddress()
        }
    }

    handleToPath(){
       this.$router.push({
           path:"/addressList",
           query:{
               select:"true"
           }
       })
    }

    handleGetAddress(): void {
       this.loadingShow = true
       GetDefaultAddress({
           userId:this.userId,
           token:this.token
       }).then( (res:any)=>{
           this.loadingShow = false
           console.log( res )
           if ( res.message.code === "200" ) {
               if ( res.data ) {
                   this.AddressData = res.data
                   this.AddressShow = true
                   this.PullAddress( res.data )
                   UserStore.SetSelAddress( res.data )
               }else{
                   this.AddressShow = false
                   Dialog.confirm({
                       title: '温馨提醒',
                       message: '请添加或选择地址',
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(()=>{
                       this.$router.push({
                           path:"/addressList",
                           query:{
                               select:"true"
                           }
                       })
                   }).catch(()=>{
                       //
                   })
               }
           }else{
               this.handleError( res.message.msg )
           }
       } )
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   @Emit("PullAddress")
   PullAddress(data:AddressType){
       return data
   }

   @Prop(Object)
   data!:AddressType

   get GetData(){
       return this.data
   }

   @Watch("GetData")
   handleChangeData(newVal:AddressType){
       this.AddressData = newVal
   }
}
