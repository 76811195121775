import axios from "axios"
import {Dialog, Notify} from "vant"
import { Express } from "./url"
import router from "@/router";

const Axios = axios.create()
Axios.defaults.baseURL = Express
Axios.defaults.headers.post["Content-Type"] = "application/json"
Axios.defaults.headers.post["type"] = "wxpublic"

Axios.defaults.timeout = 10 * 1000

// 请求前
Axios.interceptors.request.use( config=>{
    return config
},error=>{
    Promise.reject(error)
} )
// 请求后
Axios.interceptors.response.use( response=>{
    if ( response.data.message.code === "200" ){
        return response.data
    }else{
        if ( response.data.message.code === "401" ){
            Dialog.confirm({
                title: '温馨提示',
                message: '登录过期请重新登录',
                beforeClose(action, done) {
                    if (action === 'confirm') {
                        let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
                        if ( bool != null && bool[0] == "micromessenger" ){
                            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31xiaoyuan.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
                        }else{
                            router.replace({
                                name:"register"
                            })
                        }
                        done();
                    } else {
                        done();
                    }
                },
            })
        }else{
            Notify({
                message: response.data.message.msg,
                color: 'white',
                background: "rgba(0,0,0,.6)",
            });
        }
    }
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
} )

export default Axios;
