




































import { Vue,Component,Prop,Emit } from "vue-property-decorator"

 interface AddListType<T=string>{
     BackIcon:T;
     RemoveIcon:T;

     handleRemoveBtn(index:number):void;
 }

 @Component({ name:"AddList" })
 export default class AddList extends Vue implements AddListType{
     BackIcon = require("$icon/Express/Back.png");
     RemoveIcon = require("$icon/Express/Del.png");

     handleRemoveBtn(index:number){
         console.log( this.GetData )
         if( this.GetData.length ){
             let data:any = this.GetData[index]
             if( data ){
                 this.GetData.splice( index,1 )
                 this.Remove()
             }
         }
     }

     @Prop(Array)
     list!:any;

     get GetData(){
         return this.list
     }


     @Emit("Remove")
     Remove(){
         return this.GetData
     }
 }
