










































































































































































 import { Notify,Dialog,Toast } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import SelItem from '../../components/ShareComponent/PullSelItem.vue'
 import ToPathList from "@/components/ExpressComponent/ExpressIssue/ToAllList.vue"
 import AddList from "@/components/ExpressComponent/ExpressIssue/AddList.vue"
 import Copy from "@/components/ExpressComponent/ExpressIssue/Copy.vue"
 import Address from "@/components/ExpressComponent/ExpressIssue/Address.vue"
 import Stroage from '../../util/Storage'
 import { IssueUpData,IssueUpDataList } from '../../Api/Express/interfaceType'
 import { FilterPullItem } from '../../util/filter'
 import { GetTimes,GetSize,GetDefaultAddress } from '../../Api/Basics/index'
 import { IssueExpress as UpExpress } from '../../Api/Express/index'
 import { GetFrom,IssuePay,IssuePayBack } from "../../Api/Express/index"
 import { GetTimeType,GetFromType,SizeType,AddressType,CouponItem } from '../../Type/index'
 import { UpListItem } from "@/Type/StoreType"
 import { StoreExpress } from "../../store/modules/Express/Express"
 import { CouponStore } from '@/store/modules/Basics/Coupon'
 import { Vue,Component,Watch } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";
 import {setPullData} from "@/util/compatibility_App";
 // let WeixinJSBridge = ( "WeixinJSBridge" in window  ) ? window["WeixinJSBridge"] : {}; //{}
 declare let WeixinJSBridge: any;
 declare const document:any;

 interface IssueValue<T=string>{
    Money:number|T;
    KeyVal:T;
    MessageVal:T;
}

 interface IssueExpressType<T=string>{
     loadingShow:boolean;
     rightIcon:T;
     TextListIcon:T;
     AddIcon:T;
     userId:number|T;
     token:T;
     NavList:Array<{ title:T,path:T }>
     AddressShow:boolean;
     CouponData:CouponItem;
     CouponBool:boolean;
     CouponText:T;
     AddressData:AddressType
     FromList:Array<GetFromType>;
     FromIndex:number;
     SizeList:Array<SizeType>;
     SizeIndex:number;
     TimeList:Array<GetTimeType>;
     TimeIndex:number;
     StopPullItem:boolean;
     AddMoney:number;
     Money:number;
     Code:T;
     Message:T;
     SelList:boolean[];
     OrderId:T;
     OrderData:any;
     UpData:IssueUpData;
     UpList:UpListItem[];

     initData():void;
     handlePullCopy(data:any):void;
     handleAddClick(bool?:boolean):void;
     handleChangeUpList(data:any):void;
     handleToPath(state:number):void;
     handleChangeShow(idx:number,e:Event):void;
     handleSelItem(data:any):void;
     handleUpTime():void;
     handleFrom():void;
     handleSize():void;
     handleFilterSize(data:SizeType[],bool?:boolean):void;
     handleFilterUpDown():void;
     handleComputed():void;
     handleGetSelAddress(data:AddressType):void;
     handleUpBtn():void;
     handleUpData():void;
     handlePay():void;
     onBridgeReady():void;
     handleCallBack():void;
     handleError(data:string):void;
 }

 @Component({ name:"IssueExpress",components:{ SelItem,ToPathList,AddList,Copy,Address,HeadTop } })
 export default class IssueExpress extends ZoomPage implements IssueExpressType{
     loadingShow = true
     rightIcon = require("$icon/Basics/Right.png");
     TextListIcon = require("$icon/Express/TextList.png");
     AddIcon = require("$icon/Basics/Add.png")
     userId:number|string = "";
     token = ""
     NavList = [
         {
             title:"我的发布列表",
             path:""
         },
         {
             title:"代取广场",
             path:""
         }
     ]
     AddressShow = false; // 是否显示地址
     AddressData:AddressType = {}
     CouponData:CouponItem = {}; // 优惠卷地址
     CouponBool = false; // 是否可以用优惠卷
     CouponText = "请选择优惠卷"
     FromList: GetFromType<string>[] = []
     FromIndex = 0;
     SizeList: SizeType[] = []
     SizeIndex = 0;
     TimeList: GetTimeType<string>[] = []
     TimeIndex = 0;
     UpDownBool = false; // 是否上楼 true 上楼 false 不上楼
     StopPullItem = false; // 是否禁止上楼
     UpDownMoney = 0; // 上楼 费用
     AddMoney = 0; // 添加发布多个快递费用
     Money = 0;// 计算金额
     Code = ""
     Message = ""
     SelList = [ false,false,false ]
     OrderId = ""
     OrderData:any = {}
     UpData:IssueUpData = {
        addressId:"",// 地址的ID
        mailCode:"", // 取货码
        buildNumberName:"", // 宿舍名称
        name:"", // 收货的名字
        phone:"", // 收货的手机号
        mailCompanyID:"",// 取货 地址的Id
        size:"小件", // 货物的大小
        upDown:"0", // 是否上楼  默认不允许上楼
        receiveDate:"", // 送达的时间段
        amount:"", // 代取的金额
        remark:"", // 留言
        userCouponId:"", // 使用的优惠卷
     }
     UpList:UpListItem[] = []; // 发布多个列表 数据

     mounted() {
         this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
         this.token = Stroage.GetData_ && Stroage.GetData_("token")
         this.initData()
     }

     initData(){
         let SelIndex = StoreExpress.GetIssueIndex
         let ValueData = StoreExpress.GetIssueValue
         this.UpList = StoreExpress.GetIssueList
         this.FromIndex = SelIndex.FromIndex
         this.SizeIndex = SelIndex.SizeIndex
         this.TimeIndex = SelIndex.TimeIndex
         this.Money = Number( ValueData.Money )
         this.Code = ValueData.KeyVal
         this.Message = ValueData.MessageVal
         if ( StoreExpress.GetFromList.length ) {
            this.loadingShow = false
            this.FromList = FilterPullItem(StoreExpress.GetFromList,"name") as Array<GetFromType>
            this.UpData.mailCompanyID = this.FromList[this.FromIndex].id as string
            this.handleComputed()
         }else{
             this.handleFrom()
         }

         if ( StoreExpress.GetTimeList.length ) {
            this.loadingShow = false
            this.TimeList = FilterPullItem(StoreExpress.GetTimeList,"dicCode") as Array<GetTimeType>
            this.UpData.receiveDate = this.TimeList[this.TimeIndex].dicCode as string
            this.handleComputed()
         }else{
             this.handleUpTime()
         }

         if ( StoreExpress.GetSizeList.length ) {
             this.handleFilterSize( StoreExpress.GetSizeList )
         }else{
             this.handleSize()
         }

         if ( JSON.stringify( CouponStore.GetSelCoupon ) !== "{}" ) {
            this.CouponData = CouponStore.GetSelCoupon
            this.handleComputed()
         }else{
             this.CouponBool = false
             this.CouponText = "请选择优惠卷"
         }

     }

     handlePullCopy(data:any){
         if( data.code ){
             this.Code = data.code
         }
         if( data.from ){
             if( this.FromList.length ){
                 let reg = new RegExp( data.from )
                 let Index = this.FromList.findIndex( a=>reg.test( a.name ) )
                 if( Index < 0 )Index = 0;
                 if( Index != 0 ){
                     this.FromIndex = Index
                 }
             }
         }
     }

     handleAddClick(bool?:boolean){
         this.Code = this.Code.replace(/ /g,"")
         if( !this.Code.length ){
            Toast({
                message:"请输入取件号",
                icon:"none",
                className:"ToastClassName"
            })
            return;
         }
         this.UpList.push({
            fromData:this.FromList[ this.FromIndex ],
            size:this.UpData.size,
            code:this.Code,
            money:this.AddMoney
         })
        //  if( !bool ){
        //      this.handleComputed()
        //  }
         this.handleComputed()
         this.FromIndex = 0;
         this.SizeIndex = 0;
         this.Code = "";
     }

     handleChangeUpList(data:any){
         this.UpList = data
         this.handleComputed()
     }

     handleToPath(state:number){ // 0.发布列表 1.代取广场 2.选择送件地址 3.选择优惠卷
         let path = "";
         switch( state ){
             case 0:
                 path = "/myExpress"
                 break;
             case 1:
                 path = "/allExpress"
                 break;
             case 2:
                 this.$router.push({
                    path:"/selcoupon",
                    query:{
                        type:"1,0"
                    }
                 })
                 return;
         }
         if ( !path.length )return;
         this.$router.push({
             path
         })
     }

     handleChangeShow(idx:number ){
        let List:boolean[] = []
        this.SelList.forEach( (item,index)=>{
            if ( idx === index ) {
                List.push( !item )
            }else{
                List.push( false )
            }
        } )
        this.SelList = List
     }

     handleSelItem(data:any){
         switch( Number( data.state ) ){
            case 0:
                this.FromIndex = data.index
                this.UpData.mailCompanyID = this.FromList[this.FromIndex].id as string
                 break;
            case 1:
                this.SizeIndex = data.index
                this.UpData.size = this.SizeList[this.SizeIndex].dicCode as string
                 break;
            case 2:
                this.TimeIndex = data.index
                this.UpData.receiveDate = this.TimeList[this.TimeIndex].dicCode as string
                 break;
         }
        this.handleComputed()
     }

    handleUpTime(): void {
        this.loadingShow = true
        GetTimes({
            userId:this.userId,
            token:this.token
        },{
            // isAdmin:0
        }).then( (res:any)=>{
            this.loadingShow = false
            console.log( res )
            if ( res.message.code === "200" ) {
                    this.TimeList = FilterPullItem(res.data.prices,"dicCode") as Array<GetTimeType>
                    this.UpData.receiveDate = this.TimeList[0].dicCode as string
                    this.handleFilterUpDown()
                    this.handleComputed()
                    StoreExpress.SetTimeList( res.data.prices )
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleFrom(): void {
        this.loadingShow = true
        GetFrom({
            userId:this.userId,
            token:this.token
        }).then((res:any)=>{
            this.loadingShow = false
            console.log( res )
            if ( res.message.code === "200" ) {
                    this.FromList = FilterPullItem(res.data,"name") as Array<GetFromType>
                    this.UpData.mailCompanyID = this.FromList[0].id as string
                    this.handleComputed()
                    StoreExpress.SetFromList(res.data)
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleSize(): void{
        this.loadingShow = true
        GetSize({
            userId:this.userId,
            token:this.token
        }).then((res:any)=>{
            this.loadingShow = false
            console.log( res )
            if ( res.message.code === "200" ) {
                this.handleFilterSize( res.data )
                this.handleComputed()
                StoreExpress.SetSizeList(res.data)
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleFilterSize( data:SizeType[] ){
        this.loadingShow = false
        interface SizeTypeT extends SizeType{
            title?:string;
        }
        let List:Array<SizeTypeT> = []
        data.forEach((item,index)=>{
            List.push({
                ...item,
                title:item.dicCode+" " + item.dicDesc
            })
        })
        this.UpData.size = List[this.SizeIndex].dicCode as string
        this.SizeList = List
        this.handleComputed()
    }

    handleComputed(){
        let { UpList,FromList,SizeList,TimeList,FromIndex,SizeIndex,TimeIndex,UpDownBool,UpDownMoney } = this;
        if( !( FromList.length && SizeList.length && TimeList.length ) )return;
        let timeMoney = Number( TimeList[ TimeIndex ].value ) || 0,
            sizeMoney = Number( SizeList[ SizeIndex ].value ) || 0,
            upDownMoney = UpDownBool ? Number( UpDownMoney ) : 0;
        let Money = timeMoney + sizeMoney + upDownMoney ; // 计算金额
        this.AddMoney = Number( (timeMoney + sizeMoney).toFixed(2) );
        if( UpList.length ){
            let Sum = 0;
            UpList.forEach( (item)=>{
                Sum += Number( item.money )
            } )
            if( Sum > 0 ){
                // Money += Sum
                Money = Sum
            }
        }
        this.UpData.amount = Number( Money.toFixed(2) ) + ""
        if ( JSON.stringify( this.CouponData ) !== "{}" ) {
            if ( Number(this.CouponData.minAmount) <= Money ) {
                this.CouponBool = true
                Money -= Number( this.CouponData.amount ) || 0.01
                this.UpData.userCouponId = this.CouponData.id as string
                this.CouponText = `大于${ this.CouponData.minAmount }元可以使用优惠卷 · 优惠${ this.CouponData.amount }元`
                this.UpData.amount = Number( Money.toFixed(2) ) + ""
            }else{
                this.CouponBool = false
                this.UpData.userCouponId = ""
                this.CouponText = `无法使用 金额没有大于 ${ this.CouponData.minAmount }元`
            }
        }else{
            this.UpData.userCouponId = ""
        }
        if( this.Money < 0 )this.Money = 0;
        this.Money =  Number( Money.toFixed(2) )
    }

    // 是否 可以上楼
    handleFilterUpDown(){
        if ( this.TimeList.length ) {
            if ( this.TimeList[ this.TimeIndex ] ) {
                if ( this.TimeList[ this.TimeIndex ].isAllowUp == 1 ) {
                    this.StopPullItem = true
                }else{
                    this.StopPullItem = false
                }
                this.UpDownMoney = Number( this.TimeList[ this.TimeIndex ].upFee ) || 0
            }else{
                this.UpDownMoney = 2
            }
        }
    }

    // 地址处理
    handleGetSelAddress(data:AddressType){
        this.UpData.addressId = data.id as string
        this.UpData.buildNumberName = data.buildNumberName as string
        this.UpData.name = data.name as string
        this.UpData.phone = data.phone as string
    }

    //发布代取
    handleUpBtn(){
        if ( !(String(this.UpData.addressId).length) ) {
            Toast({
                message:"请选择地址",
                icon:"none",
                className:"ToastClassName"
            })
            return; }
        // if( !(this.UpData.mailCode.length) ){
        //     Toast({
        //         message:"请输入取货码",
        //         icon:"none",
        //         className:"ToastClassName"
        //     })
        //     return;
        // }
        if( this.Code ){
            this.handleAddClick()
        }

        if( this.UpList.length ){
            let UpDataList:IssueUpDataList[] = [];
            this.UpList.forEach( (item,index)=>{
                UpDataList.push({
                    mailCompanyID:item.fromData.id,
                    mailCode:item.code as string,
                    size:item.size,
                    amount:item.money
                })
            } )
            this.UpData.demandDtoList = UpDataList
        }else{
            this.handleError( "请添加发布快递" )
            return;
        }

        if( !this.UpData.remark.length ){
            Dialog.confirm({
                title: '温馨提醒',
                message: "亲！发布代取需要支付金额;\n您不考虑输入留言吗？",
                confirmButtonText:"不考虑",
                cancelButtonText:"考虑一下",
                className:"HideClassName",
                overlayClass:"MaskHideClassName"
            }).then( (res:any)=>{
                this.handleUpData()
            } ).catch( (cat:any)=>cat )
            return;
        }
        this.handleUpData()
    }

    handleUpData(){
        new Promise( (resolve,reject)=>{
            this.loadingShow = true
            UpExpress({
                userId:this.userId,
                token:this.token
            },this.UpData).then( (res=>{
                if( res.message.code === "200" ){
                    this.OrderId = res.data.mainOrderId
                    if( this.Money < 0){
                        Dialog.confirm({
                            title: '发布成功',
                            message: '点击查看订单详情 或者 返回代取广场',
                            confirmButtonText:"查看详情",
                            cancelButtonText:"返回广场",
                            className:"HideClassName",
                            overlayClass:"MaskHideClassName"
                        }).then( res=>{
                            this.$router.replace({
                                path:"/expressSuc",
                                query:{
                                    orderId:this.OrderId as string
                                }
                            })
                        } ).catch( cat=>{
                            this.$router.replace({
                                path:"/allExpress"
                            })
                        } )
                        reject(true)
                    }else{
                        resolve(res)
                    }
                }else{
                    reject(res.data)
                }
            }) )
        } ).then( (res:any)=>{
            let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
            if ( bool != null && bool[0] != "micromessenger" ){
                this.loadingShow = false
                this.$router.push({
                    path:"/goPay",
                    query:{
                        orderId:this.OrderId,
                        state:"exp",
                        money:this.Money + '',
                        price:'0'
                    }
                })
                // setPullData({ orderId:this.OrderId })
            }else{
                return (
                    IssuePay({
                        userId:this.userId,
                        token:this.token
                    },{
                        orderId:this.OrderId
                    }).then( (res:any)=>{
                        console.log( res )
                        if( res.message.code === "200" ){
                            this.OrderData = res.data
                            this.handlePay()
                        }else{
                            this.loadingShow = false
                            this.handleError( res.message.msg )
                        }
                    } )
                )
            }
        } ).catch( cat=>{
            this.loadingShow = false
            if( !cat ){
                this.handleError( cat.message.msg )
            }
        } )
    }

    handlePay(){
        if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            }else if ( "attachEvent" in document ){
                try{
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }catch (e) {
                    console.info("事件不存在")
                }
            }
        }else{
            this.onBridgeReady();
        }
    }

    onBridgeReady(){
        ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                "appId":this.OrderData.appId,//公众号名称，由商户传入
                "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
                "nonceStr":this.OrderData.nonceStr, //随机串
                "package":this.OrderData.package,
                "signType":this.OrderData.signType,//微信签名方式：
                "paySign":this.OrderData.paySign //微信签名
            },
            (res:any)=>{
                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                    this.handleCallBack()
                }
            });
    }

    handleCallBack(){
        IssuePayBack({
            userId:this.userId,
            token:this.token
        },{
            orderId:this.OrderId
        }).then(res=>{
            this.loadingShow = false
            if (res.message.code === "200") {
                Dialog.confirm({
                    title: '发布成功',
                    message: '点击查看订单详情 或者 返回代取广场',
                    confirmButtonText:"查看详情",
                    cancelButtonText:"返回广场",
                    className:"HideClassName",
                    overlayClass:"MaskHideClassName"
                }).then( res=>{
                    this.$router.replace({
                        path:"/expressSuc",
                        query:{
                            orderId:this.OrderId as string
                        }
                    })
                } ).catch( cat=>{
                    this.$router.replace({
                        path:"/allExpress"
                    })
                } )
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }

    @Watch("FromIndex")
    handleFromIndex(newVal:string|number){
        let Data = {
            FromIndex:Number( newVal ),
            SizeIndex:this.SizeIndex,
            TimeIndex:this.TimeIndex,
        }
        StoreExpress.SetIssueIndex( Data )
    }

    @Watch("SizeIndex")
    handleSizeIndex(newVal:string|number){
        let Data = {
            FromIndex:this.FromIndex,
            SizeIndex:Number( newVal ),
            TimeIndex:this.TimeIndex,
        }
        StoreExpress.SetIssueIndex( Data )
    }

    @Watch("TimeIndex")
    handleTimeIndex(newVal:string|number){
        let Data = {
            FromIndex:this.FromIndex,
            SizeIndex:this.SizeIndex,
            TimeIndex:Number( newVal ),
        }
        StoreExpress.SetIssueIndex( Data )
    }

    @Watch("Money")
    handleMoney(newVal:string|number){
        let Data:IssueValue = {
            Money:Number( newVal ),
            KeyVal:this.Code,
            MessageVal:this.Message
        }
        if ( "SetIssueValue" in StoreExpress ) {
            StoreExpress.SetIssueValue( Data )
        }
    }

    @Watch("Code")
    handleCode(newVal:string){
        this.UpData.mailCode = newVal
        let Data = {
            Money:this.Money,
            KeyVal:newVal,
            MessageVal:this.Message
        }
        StoreExpress.SetIssueValue( Data )
    }

    @Watch("Message")
    handleMessage(newVal:string){
        this.UpData.remark = newVal
        let Data = {
            Money:this.Money,
            KeyVal:this.Code,
            MessageVal:newVal
        }
        StoreExpress.SetIssueValue( Data )
    }

    @Watch("UpList")
    handleUpList(newVal:any){
        StoreExpress.SetIssueList(newVal)
    }
 }
