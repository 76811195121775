


















import { Toast } from "vant"
import { Vue,Component,Ref,Emit,Watch } from "vue-property-decorator"

interface IssueCopyType<T=string>{
    CopyIcon:T;
    PageY:number;
    PageX:number
    screenWidth:number;
    screenHeight:number;
    ChiZhi:number;
    Show:boolean;
    Value:T;
    
    init():void;
    initData(str:string):void;
    handleClick(e:any):void;
    handleBlur():void;
    handleStart(e:any):void;
    handleMove(e:any):void;
    handleEnd(e:any):void;
}

@Component({ name:"IssueCopy" })
export default class IssueCopy extends Vue implements IssueCopyType{
   CopyIcon = require("$icon/Express/List.png");
   PageY = 0;
   PageX = 0;
   MoveNumY = 0;
   MoveNumX = 0;
   screenWidth = 0;
   screenHeight = 0;
   ChiZhi = 0
   Show = true
   Value = ""
   
   mounted() {
       this.screenWidth = document.body.offsetWidth
       this.ChiZhi = this.GetCopy.offsetHeight / 72

       this.init()
   }

   init(){
       if( this.Show ){
           this.GetCopy.addEventListener("touchstart",this.handleStart)
           this.GetCopy.addEventListener("touchmove",this.handleMove)
           this.GetCopy.addEventListener("touchend",this.handleEnd)
       }else{
           this.GetCopy.removeEventListener("touchstart",this.handleStart)
           this.GetCopy.removeEventListener("touchmove",this.handleMove)
           this.GetCopy.removeEventListener("touchend",this.handleEnd)
       }
   }

   initData(str:string){
       let codeList = str.match( /\w+-\w-\w+/g )
       let fromList = str.match( /\【(.+)\】/g )
       let code = "";
       let from = ""
       
       if( codeList?.length! > 0 ){
           code = codeList![0]
       }

       if( fromList?.length! > 0 ){
           from = fromList![0]
       }

       if( code.length && from.length ){
           this.Show = true
           this.pullCode({
               code,
               from
           })
           Toast({
               message:"过滤成功",
               icon:"none",
               className:"ToastClassName"
           })
           this.Value = ""
       }

   }

   handleClick(e:any){
       this.Show = false
       try{
           this.GetInput.focus()
       }catch(cat){
           //
       }
       this.init()
   }

   handleBlur(){
       this.Show = true
       this.init()
   }

   handleStart(e: any): void {
       let Screen = e.changedTouches[0]
       this.MoveNumY = Screen.pageY  - this.GetCopy.offsetTop;
       this.MoveNumX = Screen.pageX - this.GetCopy.offsetLeft;
       this.screenHeight = document.body.scrollHeight
       document.body.style.overflow = "hidden"
   }

   handleMove(e: any): void {
       let Screen = e.changedTouches[0]
       this.PageY = Screen.pageY - this.MoveNumY
       this.PageX = Screen.pageX - this.MoveNumX
       
       if( this.PageX <= 0 ){
           this.GetCopy.style.left = 0 + "px"
           this.GetCopy.classList.add("RightCopy")
       }else{
           if( Screen.pageX > ( this.screenWidth - this.MoveNumX ) ){
               this.GetCopy.style.left = ( this.screenWidth - this.MoveNumX ) + "px"
               this.GetCopy.classList.remove("RightCopy")
           }else{
               if( this.PageX > ( this.screenWidth / 2 )  ){
                   this.GetCopy.classList.remove("RightCopy")
               }else{
                   this.GetCopy.classList.add("RightCopy")
               }
                this.GetCopy.style.left = this.PageX + "px"
           }
       }

       if( this.PageY <= ( 100 * this.ChiZhi ) ){
            this.GetCopy.style.top = ( 100 * this.ChiZhi ) + "px"
       }else{
           if( Screen.pageY > ( this.screenHeight - this.GetCopy.offsetHeight ) ){
               this.GetCopy.style.top = ( this.screenHeight - this.GetCopy.offsetHeight ) + "px"
           }else{
               this.GetCopy.style.top = this.PageY + "px"
           }
       }
   }

   handleEnd(e: any): void {
       if( this.GetCopy.classList[1] ){
           this.GetCopy.style.left = 0 + "px"
           this.GetCopy.style.right = "auto"
       }else{
           this.GetCopy.style.right = 0 + "px"
           this.GetCopy.style.left = "auto"
       }
       document.body.style.overflow = "auto" 
   }

   @Watch("Value")
   handleChangeValue( newVal:string ){
       this.initData( newVal )        
   }

   @Ref("Copy")
   copyHtml!:HTMLDivElement;

   get GetCopy(){
       return this.copyHtml
   }

   @Ref("input")
   inputHtml!:HTMLInputElement
   get GetInput(){
       return this.inputHtml
   }

   @Emit("pullCode")
   pullCode(data:any){
       return data
   }
}
